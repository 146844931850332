import React from 'react';
import { MedicalApi, DocumentApi } from 'apis';
import { useState, useEffect } from 'utils/hooks';
import { ServiceRequestType, DocumentType } from 'apis/medical';
import FilesContainer, { FilesContainerProps } from '../FilesContainer';
import { convertUTCDateToLocalDate } from 'utils/helper';

export type ServiceRequestFilesProps = FilesContainerProps & {
  serviceRequestId?: string;
  serviceRequests?: Array<ServiceRequestType> | null;
  filter?: (document: DocumentType) => boolean;
};

const documentTypeMap: Record<string, string> = {
  evaluation: 'Evaluation (Qualtrics)',
  'medical-document': 'Medical Documents',
  rfs: 'RFS 1-Page Document',
};

export default function ServiceRequestFiles(props: ServiceRequestFilesProps) {
  const { serviceRequestId, serviceRequests, filter } = props;

  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState<any>();

  useEffect(() => {
    async function fetchData() {
      // fetch notes
      if (serviceRequests || serviceRequestId) {
        setDocuments(undefined);
        setLoading(true);
        try {
          const sr = serviceRequests || [await MedicalApi.fetchServiceRequest(serviceRequestId as string)];

          // Getting files
          const allDocuments: Array<DocumentType> = [];
          const referralFiles = sr.map(({ referral_document }) => referral_document).filter((t) => t);
          const additionalFiles = sr.flatMap(({ additional_documents }) => additional_documents || []);
          const consentFiles = sr.flatMap(({ consent }) => consent || []);

          if (referralFiles.length > 0) {
            allDocuments.push(
              ...referralFiles.map((referralFile) => ({
                url: referralFile.url as string,
                description: referralFile.description,
                id: (referralFile.url || '').split('/').pop() as string,
                type: 'referral',
              }))
            );
          }

          additionalFiles.forEach((file) => {
            let description = file.description || (file.type && documentTypeMap[file.type]);

            // Backward compatibility for old RFS documents
            if (description === 'RFS Document') {
              description = 'RFS 1-Page Document';
            }
            allDocuments.push({
              url: file.url as string,
              description: description,
              id: (file.url || '').split('/').pop() as string,
              isHioperator: file.url?.includes('hioperator'),
              type: file.type,
            });
          });

          consentFiles.forEach((file) => {
            allDocuments.push({
              url: file.url as string,
              description: `${file.type}`,
              id: (file.url || '').split('/').pop() as string,
              type: 'consent',
            });
          });

          let filteredDocuments = filter ? (allDocuments || []).filter(filter) : allDocuments;

          // fetching metadata for all files
          const promises: Array<any> = [];
          filteredDocuments.forEach((d: any) => {
            promises.push(
              !d.isHioperator ? DocumentApi.fetchDocumentMeta(d.id).catch(() => false) : Promise.resolve(false)
            );
          });

          const fileMetas = await Promise.all(promises);
          const idsToRemove: Array<string> = [];
          (fileMetas || []).forEach((meta: any, i: number) => {
            if (!meta) {
              if (filteredDocuments[i].isHioperator) {
                // Hioperator files are not stored in our system, so we don't have metadata
                filteredDocuments[i].meta = {
                  fileName: filteredDocuments[i].description || '',
                  mimeType: 'application/pdf',
                  created: '',
                };
              } else {
                idsToRemove.push(filteredDocuments[i].id);
              }
            } else {
              const { filename: fileName, filesize: fileSize, mimetype: mimeType, tags, created_utc: created } = meta;
              filteredDocuments[i].meta = {
                fileName,
                fileSize,
                mimeType,
                tags,
                created: convertUTCDateToLocalDate(created),
              };
              if (filteredDocuments[i]?.type === 'referral') {
                filteredDocuments[i].displayName = fileName;
              }
            }
          });

          // removing ids where fetching meta failed (doc doesn't exist 404)
          filteredDocuments = filteredDocuments.filter((doc: DocumentType) => !idsToRemove.includes(doc.id));

          setDocuments(filteredDocuments);
        } catch (error) {
          console.error('Error while fetching sr documents', error);
          setDocuments([]);
        }
      } else {
        setDocuments([]);
      }

      setLoading(false);
    }
    fetchData();
  }, [serviceRequests, serviceRequestId]); // eslint-disable-line react-hooks/exhaustive-deps

  const inheritedProps = { ...props };
  delete inheritedProps.serviceRequestId;
  delete inheritedProps.serviceRequests;
  delete inheritedProps.filter;

  return <FilesContainer loading={loading} files={documents} {...inheritedProps} />;
}
