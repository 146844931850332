import { DocumentApi, EvaluationApi, MedicalApi, TemplateApi } from 'apis';
import { CompositionType, CompositionStatusType } from 'apis/medical';
import uniq from 'lodash/uniq';
import { QuestionnaireResponseType } from 'apis/evaluation';

export default function useRFS() {
  const generateRFSPackageBlob = async (requestId: string, serviceRequestId: string, referralNumber: string) => {
    const rfsBlob = await TemplateApi.getRfsDocumentById(requestId);

    const [questionnaireResponses, soapNotes] = await Promise.all([
      EvaluationApi.searchQuestionnaireResponseByServiceRequestId(serviceRequestId),
      MedicalApi.fetchAllSoapNote(serviceRequestId),
    ]);
    const approvedSoapNotes = soapNotes
      .filter((item) => item.status === CompositionStatusType.Approved)
      .sort(
        (a: CompositionType, b: CompositionType) =>
          new Date(a.appointment_date).getTime() - new Date(b.appointment_date).getTime()
      );

    const filteredQuestionnaireResponses = [];
    const sessionMap = new Set<number>();
    for (const qr of questionnaireResponses) {
      if (!sessionMap.has(qr.session_number)) {
        sessionMap.add(qr.session_number);
        filteredQuestionnaireResponses.push(qr);
      }
    }

    const appointmentIds = filteredQuestionnaireResponses
      .sort(
        (a: QuestionnaireResponseType, b: QuestionnaireResponseType) =>
          new Date(a.appointment_date).getTime() - new Date(b.appointment_date).getTime()
      )
      .map((qr) => qr.appointment_id);
    const uniqueAppointmentIds = uniq(appointmentIds);
    const approvedSoapNotesIds = approvedSoapNotes.map(({ id }) => id);
    const canMerge = uniqueAppointmentIds.length > 0 || approvedSoapNotesIds.length > 0;

    let combineSoapNotesAndEvaluationsBlob;

    if (canMerge) {
      combineSoapNotesAndEvaluationsBlob = await TemplateApi.combineSoapNotesAndEvaluations(
        approvedSoapNotesIds,
        uniqueAppointmentIds
      );
    }

    const [uploadedRfsDoc, combinedSoapNotesAndEvaluations] = await Promise.all([
      DocumentApi.uploadDocument(
        new File([rfsBlob], `RFS_${referralNumber}.pdf`, {
          type: 'application/pdf',
        })
      ),
      ...(canMerge ? [DocumentApi.uploadDocument(combineSoapNotesAndEvaluationsBlob)] : []),
    ]);

    const combinedBlob: Blob = await DocumentApi.mergeDocuments(
      {
        document_ids: [uploadedRfsDoc.id, ...(canMerge ? [combinedSoapNotesAndEvaluations.id] : [])],
        filename: 'Combined PDF',
      },
      false
    );

    return combinedBlob;
  };

  return {
    generateRFSPackageBlob,
  };
}
